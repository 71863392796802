body{
    background: linear-gradient(176deg,rgb(18,24,27) 50%,rgb(32,39,55) 100%);
    background-attachment: fixed;
    box-sizing: border-box;
    font-family: sofia-pro, sans-serif;
}

.topnav {
    overflow: hidden;
    color: white;
    padding-right: 20px;
    font-family: sofia-pro, sans-serif;
    padding-top: 20px;
    letter-spacing: 0.5px;
    font-weight: bold;
    cursor: pointer;
}

.topnav img{
    width: 10%;
    margin-top: -5px;
}

.topnav img:hover{
    width: 11%;
    transition: 0.3s;
}

.topnav a {
    float: left;
    color: #f5f5f5;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 15px;
}

.topnav a:hover {
    color: #ffffff;
    font-size: 16px;
    transition: 0.1s;
}

.topnav a.active {
    background-color: #04AA6D;
    color: white;
}

.topnav-right {
    float: right;
}

.title{
    /*position: fixed;*/
    text-align: center;
    letter-spacing: 1px;
    line-height: 1;
    color: white;
}

.title h1{
    margin-top: 13%;
    font-size: 5.25rem;
    font-weight: 700;
    font-family: cubano, sans-serif;
    border-width: 0;
    border-style: solid;
    background: -webkit-linear-gradient(#45fb45, #14c014);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: greenglow 1s ease-in-out infinite alternate;
}

.title #house:hover{
    font-size: 90px;
    transition: 0.3s;
}

.title p{
    font-size: 2rem;
    line-height: 2rem;
}

.title b{
    background-image: linear-gradient(to top,var(--tw-gradient-stops));
    --tw-gradient-from: #f97316;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: #eab308;
    color: #0000;
    -webkit-background-clip: text;
    animation: orangeglow 1s ease-in-out infinite alternate;
    font-size: 2.40rem;
}

.title b:hover{
    font-size: 2.80rem;
    transition: 0.3s;
    cursor: pointer;
}

.title #House{
    transform: translate(-50%, -50%);
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    cursor: pointer;
    padding: 1rem 1.5rem;
    font-family: sofia-pro,sans-serif;
    font-size: .875rem;
    font-weight: 700;
    border: none;
    border-radius: 5px;
}

.title #House a{
    text-decoration-line: none;
    color: white;
}



.column {
    float: left;
    margin-left: 5%;
    width: 40%;
    padding: 0 10px;
    margin-bottom: 3rem;
}

.row {
    margin-top: 60%;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }

    .content img{
        margin-left: 0;
    }
}

/* Style the counter cards */
.card {
    font-family: cubano,sans-serif;
    border-radius: 17px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: center;
    background-color: #2a2e35;
    color: white;
}

.card img{
    width: 94%;
    padding-top: 10px;
    border-radius: 15px;
}

.card img:hover{
    width: 98%;
    transition: 0.25s;
}

.card h1{
    font-weight: 400;
    font-size: 1.75rem;
    -webkit-text-stroke: 2px white;
    letter-spacing: 1.7px;
}

.card p{
    -webkit-text-stroke: 0.4px #a7b3c1;
    color: #a7b3c1;
    margin-left: -30px;
}

.card .button{
    -webkit-text-stroke: 0.5px white;
    color: white;
    font-size: 1rem;
    margin-top: 10px;
    padding: 0.6rem 6rem;
    background-color: #151719;
    border: 5px #26292e solid;
    border-radius: 10px;
    cursor: pointer;
}

.card .button:hover{
    -webkit-text-stroke: 0.9px white;
    font-size: 1.15rem;
    transition: 0.3s;
}

.modal{
    background-color: #2a2e35;
    margin-left: -7px;
    margin-right: -7px;
    margin-top: -37px;
    margin-bottom: -37px;
    color: white;
}

.content h3{
    -webkit-text-stroke: 0.3px white;
    font-size: 1.75rem;
    text-align: center;
    padding-top: 20px;
}

.content img{
    text-align: center;
    margin-left: 40%;
    border-radius: 5px;
}


.content b{
    margin-left: 40%;
    font-size: 20px;
}

.content p{
    text-align: center;
}

.content #last-popup{
    padding-bottom: 30px;
}

.about{
    color: rgba(140,149,162,255);
    padding-left: 35px;
    letter-spacing: 1px;
}

.about #title{
    font-size: 2.4rem;
    color: rgb(237, 239, 241);
}

.about #description{
    font-weight: lighter;
}

.about img{
    float: right;
}

.last-page-info{
    color: rgba(140,149,162,255);
    text-align: center;
}

.last-page-info #whatsapp{
    padding-right: 15px;
    color: rgba(140, 149, 162, 255);
}

.last-page-info #gmail{
    padding-right: 15px;
    color: rgba(140, 149, 162, 255);
}


.last-page-info #whatsapp:hover{
    color: rgb(238, 240, 244);
    transition: 0.3s;
    font-size: 50px;
}

.last-page-info #gmail:hover{
    color: rgb(238, 240, 244);
    transition: 0.3s;
    font-size: 50px;
}

/*Mobile And Tablet Device*/

/*Mobile Navbar*/
@media only screen and (max-device-width: 700px){
    .topnav a{
        font-size: 10px;
    }

    .topnav a:hover{
        font-size: 12px ;
    }

    .topnav img{
        width: 120px;
    }
}

/*Tablet Navbar*/
@media only screen and (max-device-width: 950px){
    .topnav a{
        font-size: 12px;
        margin-left: 1px;
    }

    .topnav a:hover{
        font-size: 15px ;
    }
}


/*Mobile Title*/
@media only screen and (max-device-width: 700px){
    .title h1{
        margin-top: 25%;
        font-size: 4rem;
        font-weight: 700;
    }

    .title p{
        font-size: 1.75rem;
        line-height: 2rem;
    }

    .title p b{
        font-size: 2.25rem;
    }

    .content img{
        margin-left: 20%;
    }

}

/*House Cards Mobile*/
@media only screen and (max-device-width: 700px){
    .column{
        margin-left: 0px;
        width: 90%;
    }
}

@keyframes greenglow{
    from {
        text-shadow: 0 0 10px #88f65b, 0 0 70px #60f415;
    }

    to {
        text-shadow: 0 0 20px #00e664, 0 0 80px #1df30d;
    }
}

@keyframes orangeglow{
    from {
        text-shadow: 0 0 10px #f6b35b, 0 0 70px #f47915;
    }

    to {
        text-shadow: 0 0 20px #f3b373, 0 0 80px #f86d00;
    }

}